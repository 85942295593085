<template>
  <v-dialog v-model="modalOpen" max-width="600px" persistent>
    <v-card>
      <v-card-title>
        <span class="text-h5">Add shareholder</span>
      </v-card-title>
      <v-card-text>
        <v-form
          id="shareholderForm"
          ref="shareholderForm"
          lazy-validation
          @submit.prevent="checkSubmit"
        >
          <SelfBuildingSpinner v-if="loading" />
          <v-container v-else>
            <v-row>
              <v-col cols="6">
                <v-autocomplete
                  v-model="shareholderId"
                  color="red darken-4"
                  outlined
                  :items="shareholders"
                  :search-input.sync="search"
                  dense
                  :loading="shareholderLoading"
                  hide-no-data
                  :error-messages="
                    requiredError($v.shareholderId, 'Client Name')
                  "
                  item-text="name"
                  item-value="shareholderId"
                  label="Shareholder"
                  @change="$v.shareholderId.$touch"
                  @blur="$v.shareholderId.$touch"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn color="red darken-4" text @click="reset">Cancel</v-btn>
        <v-btn color="green darken-4" text type="submit" form="shareholderForm"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import api from '@src/api/index.js'
import { validateError } from '@utils/validateFields'
import EventBus from '@utils/EventBus'
import { EventBusEvents } from '@src/constants/index.js'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import SelfBuildingSpinner from '@components/Shared/SelfBuildingSpinner.vue'

export default {
  name: 'AddShareholder',
  components: {
    SelfBuildingSpinner,
  },
  mixins: [validationMixin],
  props: {
    modalOpen: {
      type: Boolean,
      default: false,
    },
    clientId: {
      type: [Number, String],
      default: 0,
    },
  },
  validations() {
    return {
      shareholderId: {
        required,
      },
    }
  },
  data() {
    return {
      shareholderId: '',
      shareholders: [],
      shareholderLoading: false,
      loading: false,
      search: '',
    }
  },
  watch: {
    search(val) {
      if (this.shareholderLoading) return
      if (typeof val === 'string' && val.length > 1) {
        this.fetchShareholders({ name: val })
      }
    },
  },
  methods: {
    async fetchShareholders({ name }) {
      this.shareholderLoading = true
      try {
        const shareholders = await api.getShareholderByName({ name })
        this.shareholders = shareholders
      } catch (err) {
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      } finally {
        this.shareholderLoading = false
      }
    },
    requiredError(field, fieldName) {
      return validateError(field, ['required'], `${fieldName} is required`)
    },
    async saveClientShareholder() {
      this.loading = true
      try {
        await api.verifyShareholder({
          shareholderId: this.shareholderId,
          clientId: this.clientId,
        })
        await api.createClientShareholders({
          client: { clientId: this.clientId },
          shareholdersId: [this.shareholderId],
        })
        this.loading = false
        this.reset()
        this.$emit('getShareholders')
      } catch (err) {
        this.loading = false
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      }
    },
    reset() {
      this.$refs.shareholderForm.reset()
      this.$v.$reset()
      this.$emit('closeModal')
    },
    checkSubmit() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.saveClientShareholder()
      }
    },
  },
}
</script>
