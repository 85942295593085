<!-- eslint-disable vue/valid-v-slot -->
<template>
  <v-form ref="editClientForm" class="max-width">
    <SelfBuildingSpinner v-if="loading" />
    <div v-else>
      <v-row>
        <v-col class="px-10">
        <v-radio-group v-model="editedValues.status" mandatory row>
          <v-radio color="red darken-4" label="Active" value="active"></v-radio>
          <v-radio color="red darken-4" label="Abandoned" value="abandoned"></v-radio>
        </v-radio-group>
        </v-col>
      </v-row>
      <v-row class="px-10">
        <v-col cols="4">
          <v-text-field
            v-model="editedValues.companyName"
            color="red darken-4"
            outlined
            dense
            mandatory
            :error-messages="
              requiredError($v.editedValues.companyName, 'Company Name')
            "
            label="Company Name"
            @change="$v.editedValues.companyName.$touch"
            @blur="$v.editedValues.companyName.$touch"
          />
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="editedValues.email"
            color="red darken-4"
            outlined
            dense
            :error-messages="emailErrors"
            label="Email"
            @change="$v.editedValues.email.$touch"
            @blur="$v.editedValues.email.$touch"
          />
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="editedValues.mobileNumber"
            color="red darken-4"
            outlined
            dense
            :error-messages="phoneErrors"
            label="Mobile Number"
            @change="$v.editedValues.mobileNumber.$touch"
            @blur="$v.editedValues.mobileNumber.$touch"
          />
        </v-col>
        <v-col cols="4">
          <v-combobox
            v-model="editedValues.registrationAddress"
            :append-icon="null"
            :items="addresses"
            :search-input.sync="search"
            color="red darken-4"
            dense
            hide-no-data
            outlined
            item-text="companyName"
            item-value="id"
            :error-messages="
              requiredError(
                $v.editedValues.registrationAddress,
                'Registration address',
              )
            "
            label="Registration Address"
            @change="$v.editedValues.registrationAddress.$touch"
            @blur="$v.editedValues.registrationAddress.$touch"
          />
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="editedValues.registrationNumber"
            color="red darken-4"
            outlined
            dense
            label="Registration Number"
            :error-messages="
              requiredError(
                $v.editedValues.registrationNumber,
                'Registration Number',
              )
            "
            @change="$v.editedValues.registrationNumber.$touch"
            @blur="$v.editedValues.registrationNumber.$touch"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="editedValues.vatNumber"
            color="red darken-4"
            outlined
            dense
            label="VAT Number"
          />
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="editedValues.contactPerson"
            color="red darken-4"
            outlined
            dense
            label="Contact Person"
            :error-messages="
              requiredError($v.editedValues.contactPerson, 'Contact Person')
            "
            @change="$v.editedValues.contactPerson.$touch"
            @blur="$v.editedValues.contactPerson.$touch"
          />
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="editedValues.director"
            color="red darken-4"
            outlined
            dense
            label="Director"
            :error-messages="
              requiredError($v.editedValues.director, 'Director')
            "
            @change="$v.editedValues.director.$touch"
            @blur="$v.editedValues.director.$touch"
          />
        </v-col>
        <v-col cols="4">
          <v-menu
            ref="menuIncorporationDate"
            v-model="incorporationDateMenu"
            :close-on-content-click="false"
            :return-value.sync="incorporationDateMenu"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="editedValues.incorporationDate"
                color="red darken-4"
                outlined
                dense
                label="Incorporation Date"
                prepend-icon="mdi-calendar"
                readonly
                :error-messages="
                  requiredError(
                    $v.editedValues.incorporationDate,
                    'Incorporation date',
                  )
                "
                v-on="on"
                @change="$v.editedValues.incorporationDate.$touch"
                @blur="$v.editedValues.incorporationDate.$touch"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="editedValues.incorporationDate"
              no-title
              scrollable
              color="red darken-4"
            >
              <div class="flex-grow-1"></div>
              <v-btn
                text
                color="red darken-4"
                @click="incorporationDateMenu = false"
                >Cancel</v-btn
              >
              <v-btn
                text
                color="red darken-4"
                @click="$refs.menuIncorporationDate.save(incorporationDate)"
                >OK</v-btn
              >
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="4">
          <v-select
            v-model="editedValues.incorporationCountry"
            color="red darken-4"
            outlined
            dense
            mandatory
            :items="countryList"
            :error-messages="
              requiredError(
                $v.editedValues.incorporationCountry,
                'Incorporation country',
              )
            "
            prepend-icon="mdi-map"
            label="Country of Incorporation"
            @change="$v.editedValues.incorporationCountry.$touch"
            @blur="$v.editedValues.incorporationCountry.$touch"
          ></v-select>
        </v-col>
        <v-col cols="12" class="d-flex align-center justify-center">
          <v-btn color="teal lighten-1" dark @click="editClientDetails"
            >Save new Client Details</v-btn
          >
        </v-col>
      </v-row>
      <v-row class="pa-10">
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="shareholders"
            sort-by="shareholderId"
            class="elevation-0"
            dense
            :search="searchBar"
            no-data-text="No shareholders available"
            :loading="loading"
            loading-text="Loading shareholders..."
          >
            <template v-slot:top>
              <v-toolbar flat color="white">
                <v-toolbar-title>Shareholders</v-toolbar-title>
                <v-divider vertical class="mx-4"></v-divider>
                <div class="flex-grow-1"></div>
                <v-text-field
                  v-model="searchBar"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
                <div class="flex-grow-1"></div>
                <v-btn color="red darken-4" dark>
                  <v-icon color="white">mdi-account-plus</v-icon>
                  <span class="white--text ml-1" @click="modalOpen = true"
                    >Add Shareholder</span
                  >
                </v-btn>
                <AddShareholder
                  :client-id="clientId"
                  :modal-open="modalOpen"
                  @closeModal="closeModal"
                  @getShareholders="getShareholders"
                />
              </v-toolbar>
            </template>
            <template v-slot:item.shareholderName="{ item }">{{
              getClientName(item)
            }}</template>
            <template v-slot:item.action="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on" @click="deleteItem(item)">
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </template>
                <span>Delete shareholder</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <h2 class="mb-10">Edit documents<v-tooltip bottom max-width="250" color="rgba(0,0,0,.8)">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="blue-grey lighten-3"
              v-bind="attrs"
              class="ml-5"
              v-on="on"
            >
              mdi-help-circle-outline
            </v-icon>
          </template>
          <span
            >You can add directly any missing document. If you want to change an existing document, please remove first the old one clicking on the "x" next to the file name.</span
          >
        </v-tooltip></h2>
      <div
        v-for="(document, index) in editedDocuments"
        :key="`corporate-document-${index}`"
        class="px-5"
      >
        <p>{{ document.label }}</p>
        <v-row class="px-10">
          <v-col v-if="document.type === 'passport'" cols="4">
            <v-text-field
              v-model="document.number"
              outlined
              dense
              color="red darken-4"
              :readonly="!!document.documentId"
              label="Document Number"
            />
          </v-col>
          <v-col v-if="document.type === 'passport'" cols="4">
            <v-menu
              ref="menuDocumentExpiry"
              v-model="document.menu"
              :close-on-content-click="false"
              :return-value.sync="document.menu"
              transition="scale-transition"
              offset-y
              :disabled="!!document.documentId"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="document.expiryDate"
                  color="red darken-4"
                  outlined
                  dense
                  label="Expiry Date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="document.expiryDate"
                no-title
                :allowed-dates="allowedDates"
                scrollable
                color="red darken-4"
              >
                <div class="flex-grow-1"></div>
                <v-btn text color="red darken-4" @click="document.menu = false"
                  >Cancel</v-btn
                >
                <v-btn
                  text
                  color="red darken-4"
                  @click="
                    $refs.menuDocumentExpiry[index].save(document.expiryDate)
                  "
                  >OK</v-btn
                >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col v-if="document.documentId" cols="4">
            <v-select
              :value="document.documentName"
              :items="Array.of(document.documentName)"
              dense
              outlined
              color="red darken-4"
              label="File"
              readonly
              :append-icon="null"
              prepend-icon="mdi-paperclip"
            >
              <template v-slot:selection="{ attrs, item, selected }">
                <v-chip
                  v-bind="attrs"
                  small
                  text-color="white"
                  color="red darken-4"
                  :input-value="selected"
                  close
                  @click:close="remove(document.documentId)"
                >
                  <span>{{ item }}</span>
                </v-chip>
              </template>
            </v-select>
          </v-col>
          <v-col v-else cols="4">
            <v-file-input
              v-model="document.file"
              outlined
              dense
              color="red darken-4"
              accept="image/png, image/jpeg, pdf"
              show-size
              counter
              label="File input"
              @change="$v.editedDocuments.$each[index].file.$touch"
              @blur="$v.editedDocuments.$each[index].file.$touch"
            />
          </v-col>
        </v-row>
      </div>
      <div class="d-flex align-center justify-center">
        <v-btn color="teal lighten-1" dark @click="editClientDocuments"
          >Save Client Documents</v-btn
        >
      </div>
    </div>
  </v-form>
</template>
<script>
import { getNames } from 'country-list'
import { validationMixin } from 'vuelidate'
import { email, required } from 'vuelidate/lib/validators'
import SelfBuildingSpinner from '@components/Shared/SelfBuildingSpinner.vue'
import EventBus from '@utils/EventBus'
import api from '@src/api/index.js'
import { objectEquals } from '@src/utils/objectEquals.js'
import isAfter from 'date-fns/isAfter'
import isToday from 'date-fns/isToday'
import { EventBusEvents, ClientTypes } from '@src/constants/index.js'
import {
  mobilePhoneValidator,
  validateError,
  validExtension,
} from '@utils/validateFields'
import AddShareholder from '@components/Clients/AddShareholder.vue'

export default {
  name: 'EditClientCorporate',
  components: {
    AddShareholder,
    SelfBuildingSpinner,
  },
  mixins: [validationMixin],
  validations() {
    return {
      editedValues: {
        registrationAddress: { required },
        incorporationDate: { required },
        companyName: { required },
        email: {
          required,
          email,
        },
        mobileNumber: {
          required,
          mobilePhoneValidator,
        },
        contactPerson: { required },
        director: { required },
        incorporationCountry: { required },
        registrationNumber: { required },
      },
      editedDocuments: {
        $each: {
          type: { required },
          file: { validExtension },
        },
      },
    }
  },
  props: {
    clientId: {
      type: [String, Number],
      default: 0,
    },
  },
  data() {
    return {
      addresses: ['1, Floor 2, Falzun Street, c/w Naxxar Road, Birkirkara'],
      email: '',
      chips: '',
      search: '',
      modalOpen: false,
      editedDocuments: [],
      incorporationDateMenu: '',
      totDocuments: [
        {
          label: 'Vat certificate',
          type: 'vatCertificate',
          expiryDate: '',
          file: null,
          number: '',
          menu: '',
        },
        {
          label: 'Incorporation Certificate',
          type: 'incorporationCertificate',
          expiryDate: '',
          file: null,
          number: '',
          menu: '',
        },
        {
          label: 'M&A',
          type: 'm&a',
          expiryDate: '',
          file: null,
          number: '',
          menu: '',
        },
        {
          label: 'Letter of engagement',
          type: 'engagementLetter',
          expiryDate: '',
          file: null,
          number: '',
          menu: '',
        },
        {
          label: 'SIA Director',
          type: 'siaDirector',
          expiryDate: '',
          file: null,
          number: '',
          menu: '',
        },
        {
          label: 'SIA Company Secretary',
          type: 'siaCompanySecretary',
          expiryDate: '',
          file: null,
          number: '',
          menu: '',
        },
        {
          label: 'Authorization with MBR',
          type: 'authorizationMbr',
          expiryDate: '',
          file: null,
          number: '',
          menu: '',
        },
      ],
      searchBar: '',
      shareholders: [],
      headers: [
        {
          text: 'Shareholder',
          value: 'shareholderName',
          align: 'center',
        },
        {
          text: 'Type',
          value: 'shareholderType',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Email',
          value: 'email',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Mobile',
          value: 'mobileNumber',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Actions',
          value: 'action',
          sortable: false,
        },
      ],
      initialValues: {},
      editedValues: {
        clientName: '',
        director: '',
        passport: '',
        nationality: '',
        status: '',
        residenceCountry: '',
        email: '',
        mobileNumber: '',
        address: '',
        remarks: '',
      },
      loading: false,
      documents: [],
    }
  },
  computed: {
    countryList() {
      return getNames()
    },
    emailErrors() {
      return validateError(
        this.$v.editedValues.email,
        ['required', 'email'],
        'Email is required and should be valid'
      )
    },
    phoneErrors() {
      return validateError(
        this.$v.editedValues.mobileNumber,
        ['required', 'mobilePhoneValidator'],
        'Mobile number is required and should be valid'
      )
    },
  },
  created() {
    this.getClientByClientId(this.clientId)
    this.getDocumentsByClientId()
    this.getShareholders()
  },
  methods: {
    allowedDates(val) {
      return (
        isToday(new Date(val), new Date()) || isAfter(new Date(val), new Date())
      )
    },
    fileErrors(file) {
      return validateError(
        file,
        ['validExtension'],
        ['Extension wrong, accepted: png, jpg, pdf']
      )
    },
    closeModal() {
      this.modalOpen = false
    },
    deleteItem({ clientShareholdersId }) {
      EventBus.$emit(EventBusEvents.OPEN_MODAL, {
        title: 'Remove shareholder',
        message: 'Are you sure to remove this shareholder from this client?',
        activeCancel: true,
        confirmAction: () => this.deleteShareholder(clientShareholdersId),
      })
    },
    async deleteShareholder(clientShareholdersId) {
      this.loading = true
      try {
        await api.deleteClientShareholder(clientShareholdersId)
        this.getShareholders()
      } catch (err) {
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      } finally {
        this.loading = false
      }
    },
    getClientName({ name, shareholderType, companyName }) {
      return shareholderType === ClientTypes.INDIVIDUAL ? name : companyName
    },
    remove(documentId) {
      EventBus.$emit(EventBusEvents.OPEN_MODAL, {
        title: 'Delete document',
        message: 'Are you sure to delete this document?',
        activeCancel: true,
        confirmAction: () => this.removeDocument(documentId),
      })
    },
    editClientDetails() {
      this.$v.editedValues.$touch()
      if (!this.$v.editedValues.$invalid) {
        if (objectEquals(this.initialValues, this.editedValues)) {
          const err = { message: 'Details are the same. No update needed' }
          EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
          return
        }

        EventBus.$emit(EventBusEvents.OPEN_MODAL, {
          title: 'Edit client',
          message: 'Are you sure to edit client details?',
          activeCancel: true,
          confirmAction: () => this.editDetails(),
        })
      }
    },
    editClientDocuments() {
      this.$v.editedDocuments.$touch()
      // if (!this.$v.editedDocuments.$invalid) {
        EventBus.$emit(EventBusEvents.OPEN_MODAL, {
          title: 'Edit client',
          message: 'Are you sure to edit client documents?',
          activeCancel: true,
          confirmAction: () => this.editDocuments(),
        })
      // }
    },
    async editDocuments() {
      const documents = this.editedDocuments.filter(({ file }) => file)
      this.loading = true
      try {
        await api.updateClientDocuments({
          clientId: this.clientId,
          name: this.initialValues.companyName,
          documents,
          clientType: ClientTypes.CORPORATE,
        })
        EventBus.$emit(
          EventBusEvents.SNACKBAR_SUCCESS,
          {
            message: 'Document updated successfully!',
          },
          this
        )
        this.getDocumentsByClientId()
      } catch (err) {
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      } finally {
        this.loading = false
      }
    },
    async editDetails() {
      const payload = {
        status: this.editedValues.status,
        companyName: this.editedValues.companyName,
        email: this.editedValues.email,
        contactPerson: this.editedValues.contactPerson,
        registrationAddress: this.editedValues.registrationAddress,
        registrationNumber: this.editedValues.registrationNumber,
        clientType: ClientTypes.CORPORATE,
        director: this.editedValues.director,
        mobileNumber: this.editedValues.mobileNumber,
        incorporationDate: this.editedValues.incorporationDate,
        incorporationCountry: this.editedValues.incorporationCountry,
        vatNumber: this.editedValues.vatNumber,
        clientId: this.clientId,
      }
      this.loading = true
      try {
        await api.updateClientDetails(payload)
        EventBus.$emit(
          EventBusEvents.SNACKBAR_SUCCESS,
          {
            message: 'Details updated successfully!',
          },
          this
        )
        this.getClientByClientId()
      } catch (err) {
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      } finally {
        this.loading = false
      }
    },
    async removeDocument(documentId) {
      try {
        await api.deleteDocument(documentId)
        this.getDocumentsByClientId()
      } catch (err) {
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      }
    },
    documentsToEdit() {
      const documents = this.totDocuments.map((document) => {
        const documentAlreadyIn = this.documents.find(
          (doc) => doc.type === document.type
        )
        if (!documentAlreadyIn) {
          return document
        }
        const {
          type,
          comment,
          documentName,
          documentNumber,
          expiryDate,
          documentId,
        } = documentAlreadyIn
        return {
          label: document.label,
          comment,
          type,
          documentName,
          number: documentNumber,
          expiryDate,
          documentId,
        }
      })
      this.editedDocuments = documents
    },
    requiredError(field, fieldName) {
      return validateError(field, ['required'], `${fieldName} is required`)
    },
    async getShareholders() {
      this.loading = true

      try {
        const shareholders = await api.getShareholdersByClientShareholders(
          this.clientId
        )
        this.shareholders = shareholders
      } catch (err) {
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      } finally {
        this.loading = false
      }
    },
    async getClientByClientId() {
      this.loading = true
      try {
        const client = await api.getClientByClientId(this.clientId)
        const initialValues = {
          status: client.status,
          companyName: client.companyName,
          director: client.director,
          contactPerson: client.contactPerson,
          incorporationDate: client.incorporationDate,
          incorporationCountry: client.incorporationCountry,
          registrationNumber: client.registrationNumber,
          email: client.email,
          mobileNumber: client.mobileNumber,
          registrationAddress: client.registrationAddress,
          vatNumber: client.vatNumber,
        }

        this.initialValues = { ...initialValues }
        this.editedValues = { ...initialValues }
      } catch (err) {
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      } finally {
        this.loading = false
      }
    },
    async getDocumentsByClientId() {
      try {
        const documents = await api.getDocumentsByClientId(this.clientId)
        this.documents = documents
        this.documentsToEdit()
      } catch (err) {
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      }
    },
  },
}
</script>
<style>
.max-width {
  width: 100%;
}
</style>
