<template>
  <v-form ref="editClientForm" class="max-width">
    <SelfBuildingSpinner v-if="loading" />
    <div v-else>
      <v-row class="px-10">
        <v-col>
          <v-radio-group v-model="editedValues.status" mandatory row>
            <v-radio
              color="red darken-4"
              label="Active"
              value="active"
            ></v-radio>
            <v-radio
              color="red darken-4"
              label="Abandoned"
              value="abandoned"
            ></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row class="px-10">
        <v-col cols="4">
          <v-text-field
            v-model="editedValues.clientName"
            color="red darken-4"
            outlined
            dense
            label="Client Name"
            :error-messages="
              requiredError($v.editedValues.clientName, 'Client Name')"
            @change="$v.editedValues.clientName.$touch"
            @blur="$v.editedValues.clientName.$touch"
          />
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="editedValues.email"
            color="red darken-4"
            outlined
            dense
            :error-messages="emailErrors"
            label="Email"
            @change="$v.editedValues.email.$touch"
            @blur="$v.editedValues.email.$touch"
          />
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="editedValues.mobileNumber"
            color="red darken-4"
            outlined
            dense
            :error-messages="phoneErrors"
            label="Mobile Number"
            @change="$v.editedValues.mobileNumber.$touch"
            @blur="$v.editedValues.mobileNumber.$touch"
          />
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="editedValues.address"
            color="red darken-4"
            outlined
            dense
            :error-messages="requiredError($v.editedValues.address, 'Address')"
            label="Address"
            @change="$v.editedValues.address.$touch"
            @blur="$v.editedValues.address.$touch"
          />
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="editedValues.nationality"
            color="red darken-4"
            outlined
            dense
            label="Nationality"
            :error-messages="
              requiredError($v.editedValues.nationality, 'Nationality')
            "
            @change="$v.editedValues.nationality.$touch"
            @blur="$v.editedValues.nationality.$touch"
          />
        </v-col>
        <v-col cols="4">
          <v-select
            v-model="editedValues.country"
            color="red darken-4"
            outlined
            dense
            :items="countryList"
            :error-messages="requiredError($v.editedValues.country, 'Country')"
            prepend-icon="mdi-map"
            label="Country"
            @change="$v.editedValues.country.$touch"
            @blur="$v.editedValues.country.$touch"
          ></v-select>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="editedValues.vatNumber"
            color="red darken-4"
            outlined
            dense
            label="VAT Number"
            :error-messages="
              requiredError($v.editedValues.vatNumber, 'VAT Number')
            "
            @change="$v.editedValues.vatNumber.$touch"
            @blur="$v.editedValues.vatNumber.$touch"
          />
        </v-col>
        <v-col cols="12" class="d-flex align-center justify-center">
          <v-btn color="teal lighten-1" dark @click="editClientDetails"
            >Save changes</v-btn
          >
        </v-col>
      </v-row>
      <h2 class="mb-10"
        >Edit documents<v-tooltip bottom max-width="250" color="rgba(0,0,0,.8)">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="blue-grey lighten-3"
              v-bind="attrs"
              class="ml-5"
              v-on="on"
            >
              mdi-help-circle-outline
            </v-icon>
          </template>
          <span
            >You can add directly any missing document. If you want to change an
            existing document, please remove first the old one clicking on the
            "x" next to the file name.</span
          >
        </v-tooltip></h2
      >
      <div
        v-for="(document, index) in editedDocuments"
        :key="`individual-document-${index}`"
        class="px-5"
      >
        <p>{{ document.label }}</p>
        <v-row class="px-10">
          <v-col v-if="document.type === 'passport'" cols="4">
            <v-text-field
              v-model="document.number"
              outlined
              dense
              :readonly="!!document.documentId"
              color="red darken-4"
              label="Document Number"
              :error-messages="
                requiredError(
                  $v.editedDocuments.$each[index].number,
                  'Document Number',
                )
              "
              @change="$v.editedDocuments.$each[index].number.$touch"
              @blur="$v.editedDocuments.$each[index].number.$touch"
            />
          </v-col>
          <v-col v-if="document.type === 'passport'" cols="4">
            <v-menu
              ref="menuDocumentExpiry"
              v-model="document.menu"
              :disabled="!!document.documentId"
              :close-on-content-click="false"
              :return-value.sync="document.menu"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="document.expiryDate"
                  color="red darken-4"
                  outlined
                  dense
                  label="Expiry Date"
                  prepend-icon="mdi-calendar"
                  readonly
                  :error-messages="
                    requiredError(
                      $v.editedDocuments.$each[index].expiryDate,
                      'Expiry Date',
                    )
                  "
                  v-on="on"
                  @change="$v.editedDocuments.$each[index].expiryDate.$touch"
                  @blur="$v.editedDocuments.$each[index].expiryDate.$touch"
                />
              </template>
              <v-date-picker
                v-model="document.expiryDate"
                no-title
                :allowed-dates="allowedDates"
                scrollable
                color="red darken-4"
              >
                <div class="flex-grow-1"></div>
                <v-btn text color="red darken-4" @click="document.menu = false"
                  >Cancel</v-btn
                >
                <v-btn
                  text
                  color="green darken-4"
                  @click="
                    $refs.menuDocumentExpiry[index].save(document.expiryDate)
                  "
                  >OK</v-btn
                >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col v-if="document.documentId" cols="4">
            <v-select
              :value="document.documentName"
              :items="Array.of(document.documentName)"
              dense
              outlined
              color="red darken-4"
              label="File"
              readonly
              :append-icon="null"
              prepend-icon="mdi-paperclip"
            >
              <template v-slot:selection="{ attrs, item, selected }">
                <v-chip
                  v-bind="attrs"
                  small
                  text-color="white"
                  color="red darken-4"
                  :input-value="selected"
                  close
                  @click:close="remove(document.documentId)"
                >
                  <span>{{ item }}</span>
                </v-chip>
              </template>
            </v-select>
          </v-col>
          <v-col v-else cols="4">
            <v-file-input
              v-model="document.file"
              outlined
              dense
              color="red darken-4"
              accept="image/png, image/jpeg, pdf"
              show-size
              counter
              label="File input"
              @change="$v.editedDocuments.$each[index].file.$touch"
              @blur="$v.editedDocuments.$each[index].file.$touch"
            />
          </v-col>
        </v-row>
      </div>
      <div class="d-flex align-center justify-center">
        <v-btn color="teal lighten-1" dark @click="editClientDocuments"
          >Save New Client Documents</v-btn
        >
      </div>
    </div>
  </v-form>
</template>
<script>
import { getNames } from 'country-list'
import { validationMixin } from 'vuelidate'
import { email, required, requiredIf } from 'vuelidate/lib/validators'
import SelfBuildingSpinner from '@components/Shared/SelfBuildingSpinner.vue'
import EventBus from '@utils/EventBus'
import api from '@src/api/index.js'
import { objectEquals } from '@src/utils/objectEquals.js'
import isAfter from 'date-fns/isAfter'
import isToday from 'date-fns/isToday'
import { EventBusEvents, ClientTypes } from '@src/constants/index.js'
import {
  mobilePhoneValidator,
  validExtension,
  validateError,
} from '@utils/validateFields'

export default {
  name: 'EditClientIndividual',
  components: {
    SelfBuildingSpinner,
  },
  mixins: [validationMixin],
  validations() {
    return {
      editedValues: {
        address: { required },
        clientName: { required },
        email: {
          required,
          email,
        },
        mobileNumber: {
          required,
          mobilePhoneValidator,
        },
        nationality: {
          required,
        },
        country: {
          required,
        },
        vatNumber: {
          required,
        },
      },
      editedDocuments: {
        $each: {
          number: {
            required: requiredIf(
              ({ documentId, type }) => !documentId && type === 'passport'
            ),
          },
          expiryDate: {
            required: requiredIf(
              ({ documentId, type }) => !documentId && type === 'passport'
            ),
          },
          file: {
            required: requiredIf(
              ({ type, documentId }) => !documentId && type !== 'vatCertificate'
            ),
            validExtension,
          },
        },
      },
    }
  },
  props: {
    clientId: {
      type: [String, Number],
      default: 0,
    },
  },
  data() {
    return {
      email: '',
      chips: '',
      editedDocuments: [],
      totDocuments: [
        {
          type: 'passport',
          label: 'Passport/Id card',
          menu: '',
          number: '',
          expiryDate: '',
          file: null,
        },
        {
          type: 'reference',
          menu: '',
          number: '',
          label: 'Bank/Professional Reference',
          expiryDate: '',
          file: null,
        },
        {
          type: 'residenceProof',
          number: '',
          menu: '',
          label: 'Proof of residence',
          expiryDate: '',
          file: null,
        },
        {
          type: 'complianceCheck',
          number: '',
          menu: '',
          label: 'Compliance Check',
          expiryDate: '',
          file: null,
        },
        {
          type: 'personalQuestionnaire',
          number: '',
          menu: '',
          label: 'Personal Questionnaire',
          expiryDate: '',
          file: null,
        },
        {
          type: 'sourceWealth',
          number: '',
          menu: '',
          label: 'Source of wealth',
          expiryDate: '',
          file: null,
        },
        {
          type: 'sourceFunds',
          number: '',
          menu: '',
          label: 'Source of funds',
          expiryDate: '',
          file: null,
        },
        {
          type: 'vatCertificate',
          number: '',
          menu: '',
          label: 'Vat certificate',
          expiryDate: '',
          file: null,
        },
      ],
      initialValues: {},
      editedValues: {
        clientName: '',
        passport: '',
        nationality: '',
        email: '',
        country: '',
        status: '',
        mobileNumber: '',
        address: '',
        remarks: '',
      },
      loading: false,
      documents: [],
    }
  },
  computed: {
    countryList() {
      return getNames()
    },
    emailErrors() {
      return validateError(
        this.$v.editedValues.email,
        ['required', 'email'],
        'Email is required and should be valid'
      )
    },
    phoneErrors() {
      return validateError(
        this.$v.editedValues.mobileNumber,
        ['required', 'mobilePhoneValidator'],
        'Mobile number is required and should be valid'
      )
    },
  },
  created() {
    this.getClientByClientId(this.clientId)
    this.getDocumentsByClientId()
  },
  methods: {
    allowedDates(val) {
      return (
        isToday(new Date(val), new Date()) || isAfter(new Date(val), new Date())
      )
    },
    remove(documentId) {
      EventBus.$emit(EventBusEvents.OPEN_MODAL, {
        title: 'Delete document',
        message: 'Are you sure to delete this document?',
        activeCancel: true,
        confirmAction: () => this.removeDocument(documentId),
      })
    },
    editClientDetails() {
      this.$v.editedValues.$touch()
      if (!this.$v.editedValues.$invalid) {
        if (objectEquals(this.initialValues, this.editedValues)) {
          const err = { message: 'Details are the same. No update needed' }
          EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
          return
        }

        EventBus.$emit(EventBusEvents.OPEN_MODAL, {
          title: 'Edit client',
          message: 'Are you sure to edit client details?',
          activeCancel: true,
          confirmAction: () => this.editDetails(),
        })
      }
    },
    editClientDocuments() {
      this.$v.editedDocuments.$touch()
      // if (!this.$v.editedDocuments.$invalid) {
        EventBus.$emit(EventBusEvents.OPEN_MODAL, {
          title: 'Edit client',
          message: 'Are you sure to edit client documents?',
          activeCancel: true,
          confirmAction: () => this.editDocuments(),
        })
      // }
    },
    async editDocuments() {
      const documents = this.editedDocuments.filter(({ file }) => file)
      this.loading = true
      try {
        await api.updateClientDocuments({
          clientId: this.clientId,
          name: this.initialValues.clientName,
          documents,
          clientType: ClientTypes.INDIVIDUAL,
        })
        EventBus.$emit(
          EventBusEvents.SNACKBAR_SUCCESS,
          {
            message: 'Document updated successfully!',
          },
          this
        )
        this.getDocumentsByClientId()
      } catch (err) {
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      } finally {
        this.loading = false
      }
    },
    async editDetails() {
      const payload = {
        clientName: this.editedValues.clientName,
        nationality: this.editedValues.nationality,
        email: this.editedValues.email,
        clientType: ClientTypes.INDIVIDUAL,
        address: this.editedValues.address,
        country: this.editedValues.country,
        status: this.editedValues.status,
        mobileNumber: this.editedValues.mobileNumber,
        vatNumber: this.editedValues.vatNumber,
        clientId: this.clientId,
      }
      this.loading = true
      try {
        await api.updateClientDetails(payload)
        EventBus.$emit(
          EventBusEvents.SNACKBAR_SUCCESS,
          {
            message: 'Details updated successfully!',
          },
          this
        )
        this.getClientByClientId()
      } catch (err) {
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      } finally {
        this.loading = false
      }
    },
    async removeDocument(documentId) {
      try {
        await api.deleteDocument(documentId)
        this.getDocumentsByClientId()
      } catch (err) {
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      }
    },
    documentsToEdit() {
      const documents = this.totDocuments.map((document) => {
        const documentAlreadyIn = this.documents.find(
          (doc) => doc.type === document.type
        )
        if (!documentAlreadyIn) {
          return document
        }
        const {
          type,
          comment,
          documentName,
          documentNumber,
          expiryDate,
          documentId,
        } = documentAlreadyIn
        return {
          label: document.label,
          comment,
          type,
          documentName,
          number: documentNumber,
          expiryDate,
          documentId,
        }
      })
      this.editedDocuments = documents
    },
    requiredError(field, fieldName) {
      return validateError(field, ['required'], `${fieldName} is required`)
    },
    fileErrors(file) {
      return validateError(
        file,
        ['required', 'validExtension'],
        ['File is required', 'Extension wrong, accepted: png, jpg, pdf']
      )
    },
    async getClientByClientId() {
      this.loading = true
      try {
        const client = await api.getClientByClientId(this.clientId)
        const initialValues = {
          clientName: client.clientName,
          nationality: client.nationality,
          email: client.email,
          country: client.country,
          status: client.status,
          mobileNumber: client.mobileNumber,
          address: client.address,
          vatNumber: client.vatNumber,
        }

        this.initialValues = { ...initialValues }
        this.editedValues = { ...initialValues }
      } catch (err) {
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      } finally {
        this.loading = false
      }
    },
    async getDocumentsByClientId() {
      try {
        const documents = await api.getDocumentsByClientId(this.clientId)
        this.documents = documents
        this.documentsToEdit()
      } catch (err) {
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      }
    },
  },
}
</script>
<style>
.max-width {
  width: 100%;
}
</style>
